<template>
<tippy
		arrow
		arrowType="round"
		interactive
		theme="light action-modal"
		trigger="click"
		maxWidth="500"
		size="large"
		allowHTML
		placement="bottom-end"
		:to="to"
	>
	<div class="action-modal">
		<div class="row">
			<dropdown
				class="field"
				placeholder="DD/MM/HH"
				:colors="{
					text: 'black',
					bg: '#DFDFDF',
					border: '#DFDFDF',
				}"
			>
				<template #before>
					<date-icon class="field__icon"/>
				</template>
			</dropdown>
			<dropdown
				class="field"
				placeholder="MM:HH"
				:colors="{
					text: 'black',
					bg: '#DFDFDF',
					border: '#DFDFDF',
				}"
			>
				<template #before>
					<clock-icon class="field__icon"/>
				</template>
			</dropdown>
		</div>
		<div class="row">
			<dropdown
				class="field"
				placeholder="Schedule"
				:colors="{
					bg: '#0D69D5',
				}"
				:options="options.schedule"
				menu
				:config="{ label: 'title', trackBy: 'value' }"
			>
				<template #before>
					<calendar-icon class="field__icon"/>
				</template>
			</dropdown>
		</div>
	</div>
</tippy>
</template>

<script>
import { TippyComponent } from "vue-tippy";
import dropdown from '@/components/dropdown-base';

import clockIcon from '@/assets/icons/dashboard/clock.svg';
import dateIcon from '@/assets/icons/dashboard/date.svg';
import calendarIcon from '@/assets/icons/dashboard/calendar.svg';

import pauseIcon from '@/assets/icons/dashboard/pause.svg';
import cancelIcon from '@/assets/icons/dashboard/cancel.svg';
import deleteIcon from '@/assets/icons/dashboard/delete.svg';

export default {
	name: 'alarm-modal',
	props: {
		type: String,
		to: String,
	},
	components: {
		tippy: TippyComponent,
		dropdown,
		clockIcon,
		dateIcon,
		calendarIcon,
	},
	data() {
		return {
			options: {
				schedule: [
					{ title: 'Pause', value: 'Pause', icon: pauseIcon },
					{ title: 'Cancel', value: 'Cancel', icon: cancelIcon },
					{ title: 'Delete', value: 'Delete', icon: deleteIcon },
				],
			},
		}
	},
}
</script>

<style lang="scss">
.tippy-tooltip {
	&.action-modal-theme {
		padding: 20px;
		width: 380px;
		border-radius: 16px;
		// .tippy-roundarrow {
		// 	transform: translateX(-10px);
		// }
	}
}
</style>

<style lang="scss" scoped>
.action-modal {
	display: flex;
	flex-direction: column;
	gap: 20px;
	.row {
		display: flex;
		align-items: center;
		gap: 20px;
		width: 100%;
		.field {
			flex-grow: 1;
			&__icon {
				width: 20px;
				height: 20px;
			}
		}
	}
}
</style>